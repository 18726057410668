<template>
  <div class="container app-content pt-4">
    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" />
    <template v-else>
      <!-- Page Header -->
      <PageHeader :title="`${user.first} ${user.last}`" />

      <UserNavigation v-if="!isLoading" :user="user" />

      <dl class="row">
        <div class="col-12 col-md-4">
          <dl class="row">
            <dt class="col-12 col-sm-4">First Name</dt>
            <dd class="col-12 col-sm-8">{{ user.first }}</dd>

            <dt class="col-12 col-sm-4">Last Name</dt>
            <dd class="col-12 col-sm-8">{{ user.last }}</dd>

            <dt class="col-12 col-sm-4">Organization</dt>
            <dd class="col-12 col-sm-8">{{ user.org }}</dd>

            <dt class="col-12 col-sm-4">Email</dt>
            <dd class="col-12 col-sm-8">{{ user.email ? user.email : '--' }}</dd>

            <dt class="col-12 col-sm-4">Phone</dt>
            <dd class="col-12 col-sm-8">{{ user.phone ? user.phone : '--' }}</dd>
          </dl>
        </div>

        <div class="col-12 col-md-3">
          <dl class="row">
            <dt class="col-12 col-sm-4">Location</dt>
            <dd class="col-12 col-sm-8">{{ location }}</dd>

            <dt class="col-12 col-sm-4">Language</dt>
            <dd class="col-12 col-sm-8">{{ user.lang }}</dd>

            <dt class="col-12 col-sm-4">Timezone</dt>
            <dd class="col-12 col-sm-8">{{ user.timezone }}</dd>

            <dt class="col-12 col-sm-4">Currency</dt>
            <dd class="col-12 col-sm-8">{{ user.currency }}</dd>
          </dl>
        </div>

        <div class="col-12 col-md-5">
          <dl class="row">
            <dt class="col-12 col-sm-4">Last Login</dt>
            <dd class="col-12 col-sm-8">{{ fullDateTime(user.last_login_at) }}</dd>

            <dt class="col-12 col-sm-4">Last Activity</dt>
            <dd class="col-12 col-sm-8">{{ fullDateTime(user.last_activity_at) }}</dd>

            <dt class="col-12 col-sm-4">Last Updated</dt>
            <dd class="col-12 col-sm-8">{{ fullDateTime(user.updated_at) }}</dd>

            <dt class="col-12 col-sm-4">User Created</dt>
            <dd class="col-12 col-sm-8">{{ fullDateTime(user.created_at) }}</dd>

            <dt class="col-12 col-sm-4">Role(s)</dt>
            <dd class="col-12 col-sm-8">{{ getRoles(user.roles).join(', ') }}</dd>
          </dl>
        </div>
      </dl>
    </template>

    <Modal id="modal-user-edit">
      <template v-slot:title>Edit User</template>

      <template v-slot:body>
        <UserForm v-if="!isLoading" modal="#modal-user-edit" :initial="user" />
      </template>
    </Modal>

    <Modal id="modal-user-permissions">
      <template v-slot:title>Edit Permissions</template>

      <template v-slot:body>
        <UserPermissionsForm
          v-if="!isLoading"
          modal="#modal-user-permissions"
          :user="user"
          :defined-roles="definedRoles"
        />
      </template>
    </Modal>

    <Modal id="modal-user-delete">
      <template v-slot:title>Delete User</template>

      <template v-slot:body>
        <UserDeleteForm v-if="!isLoading" :id="Number(user.user_id)" modal="#modal-user-delete" />
      </template>
    </Modal>
  </div>
</template>

<script>
import Error from '../components/Error';
import hasTimeDate from '../mixins/hasTimeDate';
import hasUser from '../mixins/hasUser';
import Loading from '../components/Loading';
import Modal from '../components/Modal';
import PageHeader from '../components/PageHeader';
import UserForm from '../components/forms/UserForm';
import UserNavigation from '../components/users/UserNavigation';
import UserPermissionsForm from '../components/forms/UserPermissionsForm';
import UserDeleteForm from '../components/forms/UserDeleteForm';

export default {
  name: 'UsersShow',
  components: {
    UserDeleteForm,
    Error,
    Loading,
    Modal,
    PageHeader,
    UserForm,
    UserNavigation,
    UserPermissionsForm,
  },
  mixins: [hasTimeDate, hasUser],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
    };
  },
  computed: {
    location() {
      const location = [];

      if (this.user.city) {
        location.push(this.user.city);
      }

      if (this.user.state) {
        location.push(this.user.state);
      }

      if (this.user.zip) {
        location.push(this.user.zip);
      }

      if (this.user.country) {
        location.push(this.user.country);
      }

      return location.join(', ');
    },
    user() {
      return this.$store.getters['users/getCurrentUser'];
    },
  },
  created() {
    // console.log('UsersShow created() :id', this.id);
    this.fetchData();
  },
  methods: {
    fetchData() {
      // #TODO lock the page while loading
      this.error = null;
      this.isLoading = true;

      this.$store
        .dispatch('users/get', { id: this.id })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
