<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light mb-4">
    <button
      v-if="canByRole(['admin', 'developer'])"
      class="btn btn-secondary ml-auto"
      type="button"
      data-toggle="modal"
      data-target="#modal-user-edit"
    >
      <font-awesome-icon icon="edit" /> Edit
    </button>
    <button
      v-if="canByRole(['admin', 'developer'])"
      class="btn btn-secondary ml-2"
      type="button"
      data-toggle="modal"
      data-target="#modal-user-permissions"
    >
      <font-awesome-icon icon="user-tag" /> Permissions
    </button>
    <button
      v-if="canByRole(['admin', 'developer'])"
      class="btn btn-danger ml-2"
      type="button"
      data-toggle="modal"
      data-target="#modal-user-delete"
    >
      <font-awesome-icon icon="trash" />
    </button>
  </nav>
</template>

<script>
export default {
  name: 'UserNavigation',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
